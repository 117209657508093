import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";

export default function GirlsVarsity() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} sx={{ marginTop: { xs: "10px", sm: "15px", md: "20px"}}}>
                <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Girls Varsity</Typography>
                <Typography variant="body1">
                    The Girls Varsity program is for young women aged 14-18 who have not graduated from their homeschool program.
                    Occasionally a physically ready and very skilled 12 or 13 year old will be able to play in the Girls Varsity program.
                    We have a Tournament team and a League team based on skill and registration.
                    Players will try out for the teams during the first two weeks of practice in August.
                    All Girls Varsity players will practice together during August and will receive the same training during the year.
                    Details for the teams are below.
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerGirlsVarsityTournamentTeam">
                        <Typography variant={smallestVariant}>Tournament Team</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Our tournament team represents our most competitive Girls Varsity team.
                            We play in the top division of the National Tournament.
                            This team is roughly equivalent to a class B High School team.
                            The cost for this team is $290 and includes all tournaments.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: "10px"}}>The Tournament Team season consists of:</Typography>
                        <ul>
                            <li>Optional practices in July on Sundays from 6-8pm</li>
                            <li>Practices during August on Tuesdays (5:30-7:30pm), Thursdays (5:30-7:30pm), and Saturdays (1-3pm)</li>
                            <li>Practices on Saturdays from 1-3pm in September and October</li>
                            <li>Midwestern Tournament (Fort Wayne, Indiana) early September</li>
                            <li>Michigan Friendlies early October</li>
                            <li>National Tournament end of October</li>
                            <li>3-4 local friendlies</li>
                            <li>2 games mixed with top Rapids League Team</li>
                            <li>1-2 Alumni games</li>
                            <li>1-2 CoEd Games (with Boys Tournament Team)</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerGirlsVarsityLeagueTeam">
                        <Typography variant={smallestVariant}>League Team</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Our League team plays in the local <Link className="light" href="https://wnycaa.org">WNYCAA</Link> League.
                            This team is a competitive team roughly equivalent to a class D High School team.
                            The cost for this team is $150 and includes any tournaments.
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: "10px"}}>The League Team season consists of:</Typography>
                        <ul>
                            <li>Optional practices in July on Sundays from 6-8pm</li>
                            <li>Practices during August on Tuesdays (5:30-7:30pm), Thursdays (5:30-7:30pm), and Saturdays (1-3pm)</li>
                            <li>Roughly 10 league games from early September to end of October</li>
                            <li>Practices on Saturdays from 1-3pm in September and October</li>
                            <li>Binghamton Tournament mid September</li>
                            <li>Playoffs and WNYCAA Tournament end of October</li>
                            <li>2 games mixed with Rapids Tournament Team</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerGirlsVarsityPastResults">
                        <Typography variant={smallestVariant}>Past Results</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">2023</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>7th Place National HomeSchool Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2022</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>11th Place National HomeSchool Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2021</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>12th Place National HomeSchool Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2020</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2019</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>4th-8th Place HomeSchool Tournament (2nd day cancelled)</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2018</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>2nd Place National HomeSchool Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2017</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League and Tournament</li>
                                <li>1st Place Binghamton Tournament</li>
                                <li>9th Place National HomeSchool Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="body1">2016</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place WNYCAA League</li>
                                <li>1st Place Binghamton Tournament</li>
                            </ul>
                        </Typography>
                        <Typography variant="subtitle1">2015</Typography>
                        <Typography variant="body1">
                            <ul>
                                <li>1st Place Binghamton Tournament</li>
                            </ul>
                        </Typography>

                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Box>
    )
}
