import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {useMainContext} from "./constants";
import {ExpandMore} from "@mui/icons-material";

export default function CodeOfConduct() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore/>} data-testid="codeOfConduct">
                <Typography variant={smallestVariant}>Code of Conduct</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>Players</Typography>
                <Typography variant="body2">
                    <ul>
                        <li>Show respect for teammates, opponents, coaches, referees, and parents</li>
                        <li>Refrain from foul language or swearing</li>
                        <li>Refrain from arguing with referees</li>
                        <li>Refrain from displaying dirty or unsportsmanlike conduct on the field</li>
                        <li>Be a gracious winner and a gracious loser</li>
                        <li>Follow coaches instructions</li>
                        <li>Refrain from damaging equipment or property</li>
                    </ul>
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>Coaches</Typography>
                <Typography variant="body2">
                    <ul>
                        <li>Follow the players code of conduct</li>
                        <li>Set a Christian example while maintaining the conduct of the players</li>
                        <li>Refrain from proselytizing controversial points of view since there is a diverse set of beliefs among our members</li>
                        <li>Work with parents to solve discipline problems with players</li>
                        <li>Work in coordination with the referees to ensure that fans and parents behave in a sportsmanlike manner during and after games</li>
                    </ul>
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>Parents</Typography>
                <Typography variant="body2">
                    <ul>
                        <li>Follow the players code of conduct</li>
                        <li>Support the coaches. If a problem arises with a coach, the parent shall first try to resolve the problem privately with the coach.</li>
                        <li>If a problem is not able to be resolved to the satisfaction of the parent, they may work with the leader(s) to resolve the issue.</li>
                    </ul>
                </Typography>

            </AccordionDetails>
        </Accordion>
    )
}
