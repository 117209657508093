import React from 'react';
import {
    Box,
    Paper, Link as MUILink,
    Stack,
    Typography
} from "@mui/material";
import {Launch} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useMainContext} from "./constants";

export default function Home() {

    const { mainVariant, smallVariant } = useMainContext();

  return (
    <Box sx={{ maxWidth: 'xl', marginLeft: 'auto', marginRight: 'auto'}}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Box sx={ { width: { xs: 400, sm: 500, md: 800 },
            marginBottom: { xs: "10px", sm: "10px", md: "30px" },
            marginTop: { xs: "20px", sm: "40px", md: "60px"}}}>
          <img src={'rapids.svg'} width="100%"/>
        </Box>
      </Stack>
      <Box sx={{ marginBottom: { xs: "20px", sm: "30px", md: "50px"} }}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 2, sm: 4, md:6 }}>
            <Link to="/soccer" className="dark" data-testid="homeSoccerLink">
                <Paper variant="outlined" sx={{ padding: { xs: "5px", sm: "10px", md:"20px" } }}>
                    <Stack direction="column" alignItems="center" justifyContent="center">
                        <Box sx={{ width: { xs: 150, sm: 200, md: 300 } }}>
                            <img src={'soccer.svg'} width="100%" />
                        </Box>
                        <Typography sx={{ marginTop: "-10px"}} variant={mainVariant}>Soccer</Typography>
                    </Stack>
                </Paper>
            </Link>
            <Link to="/basketball" className="dark" data-testid="homeBasketballLink">
                <Paper variant="outlined" sx={{ padding: { xs: "5px", sm: "10px", md:"20px" }, textAlign: "center"} }>
                  <Stack direction="column" alignItems="center" justifyContent="center">
                      <Box sx={{ width: { xs: 150, sm: 200, md: 300 } }}>
                      <img src={'basketball.svg'} width="100%" />
                    </Box>
                    <Typography sx={{ marginTop: "-10px"}} variant={mainVariant}>Basketball</Typography>
                  </Stack>
                </Paper>
            </Link>
        </Stack>
      </Box>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={8}>
            <Typography variant={smallVariant}>
                <Link to="/about" color="inherit" className="dark" style={{ textDecoration: "none" }}>About Us</Link>
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
                <MUILink href="https://www.soccer.com/club/#/2001487456/fanwear?category=Shirts"
              color="inherit" className="dark">
                  <Typography variant={smallVariant}>Store</Typography>
                </MUILink>
                <MUILink href="https://www.soccer.com/club/#/2001487456/fanwear?category=Shirts" target="_blank"
                         color="inherit" underline="none" className="dark">
                    <Typography variant={smallVariant}>
                        <Launch fontSize="inherit" sx={{ marginTop: { xs: "9px", sm: '12px', md: '14px'}}}/>
                    </Typography>
                </MUILink>
            </Stack>
        </Stack>
    </Box>
  );
}
