import {Accordion, AccordionDetails, AccordionSummary, Link as MUILink, Box, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "./constants";
import {Link} from "react-router-dom";
import {ArrowBack, ExpandMore} from "@mui/icons-material";
import CodeOfConduct from "./CodeOfConduct";
import Eligibility from "./Eligibility";

export default function AboutUs() {

    const { mainVariant, smallVariant, smallerVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg', padding: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Link to="/" className="dark">
                    <Typography variant={smallVariant}><ArrowBack fontSize="inherit" color="inherit"/></Typography>
                </Link>
                <Typography variant={mainVariant}>About Us</Typography>
                <Box>&nbsp;</Box>
            </Stack>
            <Stack direction="column" spacing={2} sx={{ marginTop: "20px"}}>
                <Typography variant={smallerVariant}>Premier Homeschool Sports</Typography>
                <Typography variant="body1">
                    The Rochester Rapids are a competitive sports program for home educated students, based in Rochester, NY.
                    Our programs strive to coach the players both physically as well as spiritually, as we try to grow
                    young men and women who are effective on and off the field.  We receive our insurance through LEAH,
                    so membership in a LEAH is required for participation.  If you don't have a current LEAH, you can
                    join <MUILink underline="always" className="dark" href="https://www.leah.org/join-now/">Online</MUILink>.  Our programs have been successful at a national level, in our local leagues, as well as helping to grow excellent Christian men and women. Please browse around our site to get a feeling for our soccer and basketball programs. It is strongly recommended for you to read the Code of Conduct and Eligibility sections below.
                </Typography>
                <CodeOfConduct/>
                <Eligibility/>
            </Stack>
        </Box>
    )
}
