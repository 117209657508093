import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";
import CodeOfConduct from "../CodeOfConduct";
import Eligibility from "../Eligibility";

export default function SoccerHome() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingTop: { xs: "10px", sm: "15px", md: "20px"},
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} >
                    <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Rochester Rapids Soccer</Typography>
                    <Typography variant="body1">
                        Rapids soccer teams are competitive teams that play in area leagues and national tournaments.
                        Teams are available for boys and girls in the 5th-12th grade, ages 10-18 who are educated at home.
                        Ages 10-14 typically play on the Co-Ed Modified Team, and those ages 15-18 typically play on the Varsity teams.
                        Insurance is provided through <Link underline="always" className="light" href="https://www.leah.org/">LEAH</Link>, and a membership in LEAH is required to play on a Rochester Rapids Soccer Team.
                        Our season runs from early August through the end of October, with practices and games happening on Tuesdays, Thursdays, and Saturdays.
                        Practices and home games are at <Link underline="always" className="light" href="https://gandtathletics.info/sportspark/">Grace and Truth Sports Park</Link>
                        &nbsp;<Link className="light" href="https://goo.gl/maps/X51cNPW3PBv">(map)</Link>.
                        The cost for most teams is around $150, and you are required to buy one jersey for Modified and two jerseys for Varsity teams ($40 per Jersey).
                        More details are provided in the sections below and on the individual team pages.
                        The coordinator for the Rochester Rapids Soccer Program is Irv Salisbury, who can be reached at <Link className="light" underline="always" href="mailto:irv@greenGoby.com">irv@greenGoby.com</Link> or (585) 766-5103.
                    </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerGeneralSchedule">
                        <Typography variant={smallestVariant}>General Schedule</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            <div> <span style={{fontWeight: "bold"}}>Preseason: July</span> <span>(Optional)</span></div>
                            <ul>
                                <li>Sunday nights from 6-8pm in July</li>
                            </ul>
                            <div> <span style={{fontWeight: "bold"}}>Practices: August</span> <span>(Required)</span></div>
                            <ul>
                                <li>Official practices start 2nd week of August</li>
                                <li>Tuesday and Thursday from 5:30-7:30pm, Saturdays 1-3pm</li>
                            </ul>
                            <div><span style={{fontWeight: "bold"}}>Season: September-October</span> <span>(Required)</span></div>
                            <ul>
                                <li>Away games on Tuesdays, home games on Thursdays</li>
                                <li>Game time dependent on team</li>
                                <li>Practices on Saturdays from 1-3pm</li>
                                <li>Playoffs and Tournaments end of October</li>
                            </ul>
                            <div><span style={{fontWeight: "bold"}}>Winter Season: January-March</span> <span>(Optional)</span></div>
                            <ul>
                                <li>Saturday evenings at <Link className="light" href="https://maps.app.goo.gl/246JyGZzjgYAxovF6">Empire Facility</Link></li>
                                <li>Practice time depends on team</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerFaq">
                        <Typography variant={smallestVariant}>Frequently Asked Questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginBottom: "3px"}}>Do you have any cuts?</Typography>
                        <Typography variant="body1">
                            We try to make sure every home educated player has the ability to play with Rapids Soccer if they want to.
                            At the Modified level, we do not have any cuts and divide up the players into even teams based
                            on number of registrations.  At the Varsity level, we do not have cuts, but if the registered
                            number of players does not divide well into teams, we will occasionally have pool players who
                            are able to practice and play in our non competitive games.  This is a year by year decision.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginTop: "15px", marginBottom: "3px"}}>How competitive is the program?</Typography>
                        <Typography variant="body1">
                            The modified level has all levels of players, and we adjust practices to handle all different
                            skill levels.  The players will be divided into equal teams for the season.  At the Varsity
                            level we have both Tournament teams that are very competitive and league teams that are less
                            competitive.  We have handled all different skill levels on the Varsity.  We are not a "recreation"
                            program, and the players will be expected to practice accordingly.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginTop: "15px", marginBottom: "3px"}}>Playing Time</Typography>
                        <Typography variant="body1">
                            At the modified level, we do our best to play everyone at least half of the game.  This will
                            all be based on numbers who are on the teams and show up for the games.  On Varsity, there
                            is no guarantee of playing time.  At the league team level we have games that are less
                            competitive and we do our best to get everyone good playing time in those games.  When games
                            are more competitive at the Varsity level, no playing time is guaranteed.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginTop: "15px", marginBottom: "3px"}}>How far do you travel for games?</Typography>
                        <Typography variant="body1">
                            The modified teams mostly play within the greater Rochester area, but there are one or two teams that are about an hour away.
                            The Varsity league teams play mostly within an hour of Rochester, but also play a team from Elmira.  There is one tournament in Binghamton.
                            The Varsity tournament teams play one tournament in Fort Wayne Indiana, have one trip to Michigan, and play in the Nationals in Tennessee.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <CodeOfConduct/>
                <Eligibility/>
            </Stack>
        </Box>
    )
}
