import React from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {ThemeProvider} from "@emotion/react";
import {
    Box,
    createTheme,
    CssBaseline, useMediaQuery, useTheme
} from "@mui/material";
import { Outlet } from "react-router-dom";
import {MainContext} from "./constants";

let darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.between('sm','md'));
    const xsmall = useMediaQuery(theme.breakpoints.down('sm'));

    let mainContext : MainContext = {
        mainVariant: "h2",
        smallVariant: "h3",
        smallerVariant: "h5",
        smallestVariant: "h6",
        mediumOrGreater: !small && !xsmall,
        smallOrGreater: !xsmall
    }

    if( small ){
        mainContext.mainVariant = "h3";
        mainContext.smallVariant = "h4";
        mainContext.smallerVariant = "h5";
        mainContext.smallestVariant = "h6";
    }
    else if( xsmall ){
        mainContext.mainVariant = "h4";
        mainContext.smallVariant = "h5";
        mainContext.smallerVariant = "h6";
        mainContext.smallestVariant = "subtitle1";
    }

  return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
          <Box height="100%">
              <Outlet context={mainContext}/>
          </Box>
      </ThemeProvider>
  );
}

export default App;
