import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {useMainContext} from "./constants";
import {ExpandMore} from "@mui/icons-material";

export default function Eligibility() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore/>} data-testid="eligibility">
                <Typography variant={smallestVariant}>Eligibility</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>Players</Typography>
                <Typography variant="body2">
                    <ul>
                        <li>Must be home educated during the timeframe of their participation</li>
                        <li>Must meet age eligibility requirements for their team.  (See individual teams pages for details)</li>
                    </ul>
                </Typography>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold"}}>Coaches</Typography>
                <Typography variant="body2">
                    <ul>
                        <li>Must be 20 years or older, preferably with experience in their respective sport</li>
                        <li>The Club recognizes that marriage is one man with one woman, so the coach must agree with and fully represent this view</li>
                        <li>Shall be approved by the leader(s)</li>
                    </ul>
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}
