import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";
import CodeOfConduct from "../CodeOfConduct";
import Eligibility from "../Eligibility";

export default function BasketballHome() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingTop: { xs: "10px", sm: "15px", md: "20px"},
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} >
                    <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Rochester Rapids Basketball</Typography>
                    <Typography variant="body1">
                        The Rochester Rapids basketball program is for home educated youth in grades 5-12.
                        We have boys & girl’s varsity, junior varsity, modified, and 5/6th-grade basketball teams: colors are black, dark green, and white.
                        The program was started in the fall of 1994 because home-educated youth have no scholastic athletic opportunities as they progress into the upper grades.
                        NYS Education regulations do not allow a home-educated youth to participate on a public school athletic team, nor will they will allow Section V teams to compete against a homeschool team such as the Rapids.
                        We are comparable to a Section V Class C/D level school.
                        More details are provided on the individual team pages.
                        The coordinator for the Rochester Rapids Basketball Program is Mike Bushen, who can be reached at <Link className="light" underline="always" href="mailto:mmbushen@gmail.com">mmbushen@gmail.com</Link> or (585) 413-7962.
                    </Typography>
                <CodeOfConduct/>
                <Eligibility/>
            </Stack>
        </Box>
    )
}
