import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";

export default function Girls() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} sx={{ marginTop: { xs: "10px", sm: "15px", md: "20px"}}}>
                <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Girls Varsity Basketball</Typography>
                <Typography variant="body1">
                    Information coming about Girls Varsity Basketball
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="girlsBasketballJV">
                        <Typography variant={smallestVariant}>Girls JV Basketball</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Information coming about Girls JV Basketball
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="girlsBasketballModified">
                        <Typography variant={smallestVariant}>Girls Modified Basketball</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            Information coming about Girls Modified Basketball
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Box>
    )
}
