import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {useOutletContext} from "react-router-dom";

export type AppVariant = OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;

export interface MainContext {
    mainVariant: AppVariant;
    smallVariant: AppVariant;
    smallerVariant: AppVariant;
    smallestVariant: AppVariant;
    mediumOrGreater: boolean;
    smallOrGreater: boolean;
}

export function useMainContext() : MainContext {
    return useOutletContext();
}