import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";

export default function Modified() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} sx={{ marginTop: { xs: "10px", sm: "15px", md: "20px"}}}>
                <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Coed Modified</Typography>
                <Typography variant="body1">
                    Our Co-ed Modified Teams are for boys and girls in the 5th-8th grade, ages 10-14 who are educated at home.
                    (If your 15th birthday is before September 1st of the year of the season, you cannot play on Modified)
                    We allow physically ready 9 year olds on a case by case basis.
                    We are Co-ed because the league we play in is co-ed.
                    Our modified teams play in a local Christian School league for modified teams, the WNYCMSL.
                    Each year we evaluate how many players we have and then decide how we will arrange the Modified Teams.
                    We have been blessed to have enough players for four teams this past year, and we pray that will continue.
                    We divide the teams evenly after the first two weeks of practice in August, making sure siblings are together and satisfying as many carpool requests as possible.
                    Our teams are very competitive in their leagues, with our teams often winning the league and the rest placing in the top 6.
                    Our Modified Teams are an excellent starting point for your son’s or daughter’s soccer experience.
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="soccerModifiedSeason">
                        <Typography variant={smallestVariant}>Season</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" sx={{ marginTop: "10px"}}>The Modified season consists of:</Typography>
                        <ul>
                            <li>Optional practices in July on Sundays from 6-8pm</li>
                            <li>Practices during August on Tuesdays (5:30-7:30pm), Thursdays (5:30-7:30pm), and Saturdays (1-3pm)</li>
                            <li>Around 10 WNYCMSL league games in September and October</li>
                            <li>Practices on Saturdays from 1-3pm in September and October</li>
                            <li>Playoffs and Championship end of October</li>
                            <li>We do our best to have away games on Tuesdays and home games on Thursdays</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Box>
    )
}
