import {Accordion, AccordionDetails, AccordionSummary, Box, Link, Paper, Stack, Typography} from "@mui/material";
import {useMainContext} from "../constants";
import {ExpandMore} from "@mui/icons-material";

export default function Boys() {

    const { mainVariant, smallVariant, smallerVariant, smallestVariant } = useMainContext();

    return (
        <Box sx={{ maxWidth: 'lg',
            paddingLeft: { xs: "10px", sm: "20px", md: "30px"},
            paddingRight: { xs: "10px", sm: "20px", md: "30px"},
            paddingBottom: { xs: "10px", sm: "20px", md: "30px"},
            marginLeft: "auto", marginRight: "auto" }}>
            <Stack direction="column" spacing={2} sx={{ marginTop: { xs: "10px", sm: "15px", md: "20px"}}}>
                <Typography variant={ smallerVariant } sx={{ fontWeight: "bold"}}>Boys Varsity Basketball</Typography>
                <Typography variant="body1">
                    The Boys Varsity Team is a member of the 10 school Greater Finger Lakes Christian Athletic Association (GFLCAA).
                    The league has a north and a south division. We play a home and away game with all the teams in the north division.
                    Our Boys team has won 5 of the last 8 championships. We may travel to Syracuse, Elmira, Dundee, East Aurora, & Olean.
                    At the end of the season the 4 highest teams in each division advance to the playoffs.
                    The winners of the quarter-final round advance to the final four tournament.
                    In late February, the top 2 teams from each division play in the semi-finals and the winner’s meet in
                    the championship game, which is followed by an all-star game. We also schedule non-league games with
                    local CYO teams in Rochester. These games are good for us because they are usually competitive and local.
                    We complete our season by attending the very competitive NCSAA Basketball Championships at Mt Vernon Nazarene University
                    in Mt Vernon, Ohio. This tournament is optional & requires travel & overnight stay.
                </Typography>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="basketballBoysJV">
                        <Typography variant={smallestVariant}>Boys JV Basketball</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            The Boys JV Team encompasses grades 7-11 Most of the teams we play in the north division of the Varsity league also have JV teams.
                            These games will all be doubleheaders with the JV team playing prior to the Varsity.
                            The JV team will also play some local CYO teams and tournaments.
                            There are no JV playoffs but we usually enter a local tournament or 2.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="basketballBoysModified">
                        <Typography variant={smallestVariant}>Boys Modified Basketball</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1">
                            The Boys Modified Team encompasses grades 5 – 8 and plays in the Western New York Christian Athletic
                            Association (WNYCAA). This league plays about 10 – 12 games in the Rochester area.
                            Almost all of the games are on Tuesday & Thursday starting at 4:00.
                            Some of the teams in this league are St. Paul Lutheran, East Palmyra Christian School, & Rochester
                            School for the Deaf. If the modified team has some strong & experienced players, we will look for
                            opportunities that will challenge them in a more competitive environment.
                            This could include local leagues/tournaments and only the “Select” modified team will play these games.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore/>} data-testid="basketballBoysFaq">
                        <Typography variant={smallestVariant}>Frequently Asked Questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" sx={{fontWeight:"bold"}}>What about Cuts?</Typography>
                        <Typography variant="body1">
                            We maintain a maximum of 10-12 players on the team. If more than 12 players are interested in participating,
                            we conduct a tryout session. Using a series of drills, the boys are evaluated on team/individual skills,
                            offensive/defensive techniques, speed, agility, strength, conditioning, hustle & ATTITUDE.
                            Candidates exhibiting the most skill or greatest potential are selected accordingly.
                            If we have enough coaches & players, we will consider having 2 teams.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginTop: "10px"}}>Games</Typography>
                        <Typography variant="body1">
                            The Varsity & JV play our home games at St Jude’s. We travel to Syracuse, Elmira, Dundee, East Aurora, & Olean.
                            The games could be any night of the week except Sunday, but the majority of the games seem
                            to end up on Tues, Thurs, Fri & Sat afternoon. Start times vary but are usually around 6:30 (12 – 2PM on Sat).
                            Some Modified games will start at 4:00 and are almost always on Tues & Thurs. Select Modified games could be on a weekend.
                        </Typography>
                        <Typography variant="body1" sx={{fontWeight:"bold", marginTop: "10px"}}>Playing Time</Typography>
                        <Typography variant="body1">
                            For Modified & JV, if a player is in good standing & is attending practices, they will play in the games.
                            The PT will not be equally divided.
                            For Varsity, prior to each game, the coach will determine the five players who will start
                            and play the most minutes per game. Selection is based on observable skill level, game plan,
                            practice attendance, and attitude. The remaining players will be used for substitutions.
                            Some players may not play in a given game.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </Box>
    )
}
