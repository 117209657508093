import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Box, Button, createTheme, CssBaseline, IconButton, Link as MUILink, Menu, MenuItem,
    Paper,
    Stack, ThemeProvider,
    Toolbar,
    Typography
} from "@mui/material";
import {useMainContext} from "../constants";
import {Link, NavLink, Outlet} from "react-router-dom";
import {ArrowBack, ExpandMore, Launch, Menu as MenuIcon} from "@mui/icons-material";
import React, {useState} from "react";

const pages = [
    { label: "Boys Varsity", page: "boysVarsity" },
    { label: "Girls Varsity", page: "girlsVarsity" },
    { label: "Coed Modified", page: "modified" }
];

let lightTheme = createTheme({
    palette: {
        mode: 'light',
    },
});

export default function Soccer() {

    const mainContext = useMainContext();
    const {mainVariant, smallVariant, smallerVariant, mediumOrGreater} = mainContext;

    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const openNavMenu = (element:HTMLElement) => {
        setMenuAnchor(element);
    };

    const closeNavMenu = () => {
        setMenuAnchor(null);
    };

    return (
        <Box sx={{
           height: "100%",
            backgroundColor: "#eeeeee"
        }}>
            <Box sx={{
                maxWidth: 'lg',
                height: "100%",
                padding: {xs: "10px", sm: "20px", md: "30px"},
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "#eeeeee"
            }}>
                <AppBar position="static">
                    <Toolbar disableGutters={true}>
                        { !mediumOrGreater &&
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Box>
                                    <IconButton
                                        size="large"
                                        onClick={(event) => openNavMenu(event.currentTarget)}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={menuAnchor}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(menuAnchor)}
                                        onClose={() => closeNavMenu()}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >  { pages.map(  (page ) => (
                                        <MenuItem key={page.page} onClick={() => closeNavMenu()}>
                                            <NavLink to={page.page} className="dark">
                                                {page.label}
                                            </NavLink>
                                        </MenuItem>
                                    ))}
                                        <MenuItem key="store" onClick={() => closeNavMenu()}>
                                            <MUILink href="https://www.soccer.com/club/#/2001487456/fanwear?category=Shirts"
                                                     className="dark">Store</MUILink>
                                        </MenuItem>
                                    </Menu>
                                </Box>
                                <Box sx={{ marginTop: '5px'}}>
                                    <Link to="/">
                                        <img src={'/rapids.svg'} height="30px"/>
                                    </Link>
                                </Box>
                                <Box sx={{ marginRight: "10px", marginLeft: "10px", marginTop: '7px'}}>
                                    <Link to="/soccer">
                                        <img src={'/soccer.svg'} height="30px"/>
                                    </Link>
                                </Box>
                            </Stack>
                        }
                        { mediumOrGreater &&
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" id="mainBar">
                                <Box sx={{ marginLeft: "20px", marginTop: '10px', paddingBottom: '4px', paddingTop: '4px'}}>
                                    <Link to="/">
                                        <img src={'/rapids.svg'} height="40px"/>
                                    </Link>
                                </Box>
                                <Stack direction="row" alignItems="center" spacing={3}>
                                    {pages.map((page) => (
                                        <NavLink to={page.page} className="dark">
                                            {page.label}
                                        </NavLink>
                                    ))}
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <MUILink href="https://www.soccer.com/club/#/2001487456/fanwear?category=Shirts"
                                                 className="dark">Store</MUILink>
                                        <MUILink href="https://www.soccer.com/club/#/2001487456/fanwear?category=Shirts" target="_blank"
                                                 className="dark">
                                            <Launch fontSize="inherit" sx={{ marginTop:'7px'}}/>
                                        </MUILink>
                                    </Stack>
                                </Stack>
                                <Box sx={{ marginRight: "20px", paddingTop: "10px", paddingBottom: "4px" }}>
                                    <Link to="/soccer">
                                        <img src={'/soccer.svg'} height="40px"/>
                                    </Link>
                                </Box>
                            </Stack>
                        }
                    </Toolbar>
                </AppBar>
                <Box sx={{ backgroundColor: "#ffffff", borderColor: "#dddddd", borderStyle: "solid", borderWidth: "1px"}}>
                    <ThemeProvider theme={lightTheme}>
                        <CssBaseline/>
                        <Outlet context={mainContext}/>
                    </ThemeProvider>
                </Box>
            </Box>

        </Box>
    )
}
