import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./Error";
import AboutUs from "./AboutUs";
import Home from "./Home";
import Soccer from "./soccer/Soccer";
import Basketball from "./basketball/Basketball";
import SoccerHome from "./soccer/SoccerHome";
import BoysVarsity from "./soccer/BoysVarsity";
import GirlsVarsity from "./soccer/GirlsVaristy";
import Modified from "./soccer/Modified";
import BasketballHome from "./basketball/BasketballHome";
import Boys from "./basketball/Boys";
import Girls from "./basketball/Girls";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "about",
                element: <AboutUs/>
            },
            {
                path: "soccer",
                element: <Soccer/>,
                children: [
                    {
                        index: true,
                        element: <SoccerHome/>
                    },
                    {
                        path: "boysVarsity",
                        element: <BoysVarsity/>
                    },
                    {
                        path: "girlsVarsity",
                        element: <GirlsVarsity/>
                    },
                    {
                        path: "modified",
                        element: <Modified/>
                    }
                ]
            },
            {
                path: "basketball",
                element: <Basketball/>,
                children: [
                    {
                        index: true,
                        element: <BasketballHome/>
                    },
                    {
                        path: "boys",
                        element: <Boys/>
                    },
                    {
                        path: "girls",
                        element: <Girls/>
                    }
                ]
            }
        ]
    }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
